import React from 'react'
import { Container, Typography } from '@mui/material'
import Link from 'react-router-dom/Link'
import { Button } from '@mui/material';
export default function FooterComponent() {
  return (
    <>
        <Container style={{backgroundColor: '#0A1B29', textAlign:'center', padding: '30px'}} maxWidth='fluid'>
                <Container>
                    <img width='150px' src='./logo-mnd.png'/>
                </Container>

                <Container sx={{mt:2}}>
                        <Button href='https://senia.bj' style={{color:'white', textDecoration:'none'}}><span style={{ margin: '20px'}}>senia.bj</span></Button>
                        <Button href='https://asin.bj' style={{color:'white', textDecoration:'none'}}><span style={{ margin: '20px'}}>asin.bj</span></Button>
                        <Button href='https://numerique.gouv.bj' style={{color:'white', textDecoration:'none'}}><span style={{ margin: '20px'}}>numerique.gouv.bj</span></Button>
                        <Button href='https://isheero.com' style={{color:'white', textDecoration:'none'}}><span style={{ margin: '20px'}}>isheero.com</span></Button>
                        <Button href='https://semecity.bj' style={{color:'white', textDecoration:'none'}}><span style={{ margin: '20px'}}>semecity.bj</span></Button>
                </Container>
        </Container>
    </>
  )
}
