import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

// Initialize Firebase Variables Here
const app = firebase.initializeApp({
  apiKey: "AIzaSyCyHm1U6-Ip05MBYlwVFDdDgkB6kiZuNeM",
  authDomain: "languesbj-6a533.firebaseapp.com",
  projectId: "languesbj-6a533",
  storageBucket: "languesbj-6a533.appspot.com",
  messagingSenderId: "576628876075",
  appId: "1:576628876075:web:f2cd9a7355330c3dc8db6e",
  measurementId: "G-9HHP48X4R7",
});

//self.FIREBASE_APPCHECK_DEBUG_TOKEN = '53C7C7E4-8ABF-40E4-83CA-AC0AE4B8900E' //process.env.APP_CHECK_DEBUG_TOKEN_FROM_CI;
//firebase.appCheck().activate('53C7C7E4-8ABF-40E4-83CA-AC0AE4B8900E');

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
