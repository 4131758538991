import React from "react";
import { useEffect, useState } from "react";
import { db, storage } from "../Providers/firebase";
import HeaderComponent from "../Components/HeaderComponent";
import Container from "@mui/material/Container";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import FooterComponent from "../Components/FooterComponent";
import LangueComponent from "../Components/LangueComponent";

export default function ListenScreen() {
  let selectedLangue = "FON";
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [defaultRating, setDefaultRating] = useState(2);
  const [randomWord, setRandomWord] = useState(null);
  const [isSubmitAndNext, setIsSubmitAndNext] = useState(true);
  const [randomWordDocumentID, setIsRandomWordDocumentID] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    LoadAudio();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  async function LoadAudio() {
    db.collection("audiometadata")
      .where("langue", "==", selectedLangue)
      .onSnapshot((snapshot) => {
        let randomID = Math.floor(Math.random() * snapshot.docs.length);
        let randomWord = snapshot.docs[randomID].data();
        let randomWordDocumentID = snapshot.docs[randomID].id;

        setRandomWord(randomWord);
        setIsRandomWordDocumentID(randomWordDocumentID);
        setIsLoading(false);
      });
  }

  const PlayAudio = () => {
    let audio = document.getElementById("wordAudio");
    audio.play();
    setIsPlaying(true);
  };

  const StopAudio = () => {
    let audio = document.getElementById("wordAudio");
    audio.pause();
    setIsPlaying(false);
  };

  const ratingChanged = (newRating) => {
    setDefaultRating(newRating);
    setIsSubmitAndNext(false);
  };

  async function SubmitRating() {
    try {
      db.collection("audioRatings").add({
        word: randomWord.word,
        docID: randomWordDocumentID,
        rating: defaultRating,
        langue: selectedLangue,
      });

      setOpen(true);
      await LoadNextWord();
    } catch (error) {
      console.log(error);
    }
  }

  async function LoadNextWord() {
    setIsLoading(true);
    setIsPlaying(false);
    setDefaultRating(2);
    setRandomWord(null);
    setIsSubmitAndNext(false);
    await LoadAudio();
  }

  function onSelectedLangue(langue) {
    selectedLangue = langue;
    LoadNextWord()
  }

  return (
    <>
      <HeaderComponent />

      {/* Recording Container  */}
      <Container>
        <Container maxWidth="sm" sx={{ mt: 10 }}>
          <LangueComponent defaultValue={selectedLangue} onChange={onSelectedLangue} />
        </Container>
        <Container maxWidth="sm" sx={{ mt: 2 }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Merci d'avoir soumis votre appréciation !"
          />

          <Paper sx={{ p: 6, textAlign: "center" }} elevation={3}>
            {isLoading ? (
              <p>Nous n'ayons aucun enregistrement pour le moment.</p>
            ) : (
              <>
                <p>
                  Cliquez sur le bouton de lecture audio pour écouter
                  l'enregistrement.
                </p>
                <h1>{randomWord.word}</h1>

                <audio
                  style={{ display: "none" }}
                  id="wordAudio"
                  controls
                  src={randomWord.url}
                ></audio>
                <br />

                {!isPlaying ? (
                  <Button onClick={PlayAudio} variant="outlined" color="error">
                    Ecouter l'audio
                  </Button>
                ) : (
                  <Button onClick={PlayAudio} variant="outlined" color="error">
                    Rejouer l'audio
                  </Button>
                )}

                {/* Rating Form Data */}

                <Box
                  sx={{
                    mt: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>A quel point appréciez vous cet enregistrement?</p>
                  <Stack spacing={2}>
                    <Rating
                      onChange={(e, newValue) => ratingChanged(newValue)}
                      name="size-large"
                      defaultValue={defaultRating}
                      size="large"
                    />
                  </Stack>
                  <Button
                    onClick={SubmitRating}
                    disabled={isSubmitAndNext}
                    variant="outlined"
                    sx={{ mt: 2 }}
                  >
                    Soumettre et écouter une autre
                  </Button>
                </Box>
              </>
            )}
          </Paper>
        </Container>
      </Container>
      <Box sx={{ mt: 2 }}>
        <FooterComponent />
      </Box>
    </>
  );
}
