import React from "react";
import Container from "@mui/material/Container";
import HeaderComponent from "../Components/HeaderComponent";
import FooterComponent from "../Components/FooterComponent";
import { Box } from "@mui/system";
export default function AboutScreen() {
  return (
    <>
      <HeaderComponent />

      <Container sx={{ mt: 10 }}>
        <h2 style={{ fontWeight: "600", fontSize: "30px" }}>Nos partenaires</h2>
        <img
            fullWidth
            src="./partenaire.png"
            alt="Partenaire"
            style={{ width: "100%" }}
          />
      </Container>

      <Box sx={{ mt: 6 }}>
        <FooterComponent />
      </Box>
    </>
  );
}
