import React from 'react'
import Container from '@mui/material/Container'
import HeaderComponent from '../Components/HeaderComponent'
import FooterComponent from '../Components/FooterComponent'
import { Box } from '@mui/system'

export default function ContactScreen() {
    const items = [
        'Pour les lecteurs : Les participants peuvent lire un texte dans leur langue maternelle, décrire une image, et envoyer leurs contributions via la plateforme www.langues.gouv.bj',
        "Pour ceux qui préfèrent parler : Il est également possible d'envoyer par WhatsApp au 47 10 10 00 une phrase simple dans leur langue : « Bonjour, je parle (votre langue) » , suivie d'une phrase simple de leur choix ex : « ma ville est la plus belle du monde »."
    ];
    return (
        <>
            <HeaderComponent />
  
            <Container sx={{mt:10}}>

            <h2 style={{fontWeight:'600', fontSize:'30px'}}>JE PARLE MA LANGUE BENINOISE (#JAIMEMALANGUE)</h2>

            <p>L'Agence des Systèmes d'Information et du Numérique (ASIN) et le le Ministère du Numérique et de la Digitalisation, en partenariat avec Sèmè City et iSheero, lancent une campagne de collecte de données participatives dédiée à la valorisation des langues nationales béninoises.

            Cette initiative est conçue pour enrichir la diversité linguistique dans le domaine de l'intelligence artificielle. Les participants sont invités à contribuer de deux manières :       </p>

            <ol>
                {items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ol>
            
            <p>Ces contributions serviront à créer une base de données robuste pour le développement d'outils capables de comprendre et d'interagir dans les langues nationales béninoises.

            Pour participer, rendez-vous sur www.langues.gouv.bj ou envoyez vos messages vocaux par WhatsApp au 47 10 10 00.

            Nous appelons tous les Béninois à participer à cette initiative, pour aider à préserver et valoriser notre riche patrimoine linguistique dans l'ère numérique.</p>

            <h2 style={{fontWeight:'600', fontSize:'30px'}}>A propos de l’ASIN</h2>

            <p>L’ASIN est l’agence gouvernementale, chargée d’assurer l’exécution opérationnelle des programmes et projets numériques du gouvernement béninois. L’agence met en œuvre la stratégie de l’Etat en matière de développement du digital, promeut l’adoption des services numériques et le développement de leur usage auprès des citoyens et des entreprises. L’agence adopte une approche participative avec l’ensemble des parties prenantes de l’écosystème (secteur public et privé, opérateurs télécoms, bailleurs, banques de développement, incubateurs, associations) pour accélérer la transformation digitale à l’échelle du pays.</p>
            
            </Container>
  
            <Box sx={{mt:6}} >
                <FooterComponent />
            </Box>
         </>
    )
}