import React from 'react'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
export default function LangueComponent({onChange, defaultValue: defaultValue = ''}) {
  const langues ={
    "FON": "FON",
    "DENDI": "DENDI",
    "YORUBA": "YORUBA",
    "BAATONUM": "BAATONUM",
    "GUN": "GUN",
    "ADJA": "ADJA",
    "MINA": "MINA",
    "SAHOUE": "SAHOUE",
    "TEM": "TEM",
    "YOM": "YOM",
    "LOKPA": "LOKPA",
    "NATENI": "NATENI",
    "BIALI": "BIALI",
    "WAMA": "WAMA",
    "DITAMMARI": "DITAMMARI",
    "ANII": "ANII",
    "FULFULDE": "FULFULDE",
    "BOO": "BOO"
  }

  const [langue, setLangue] = React.useState('');

  const handleChange = (event) => {
    console.log(event.target.value);
    setLangue(event.target.value);
    onChange(event.target.value);
  };
  React.useEffect(() => {
    setLangue(defaultValue)
  }, [defaultValue])

  return (
    <>
        <FormControl fullWidth>
                          <Select
                            value={langue}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Langue" }}
                          >
                            <MenuItem value="">
                              <em>Selectionnez une langue</em>
                            </MenuItem>
                            {Object.keys(langues).map((langue) => (
                              <MenuItem key={langue} value={langue}>
                                {langue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
    </>
  )
}
